<template>
  <div>
    <div>
      <div class="row align-items-center ">
        <div class="col-auto">
          <img v-image="product.imageUrl" class="img-cover-40 border bg-white">
        </div>
        <div class="col-9 col-sm-10 pl-0">
          <p class=" m-0">
            <small>
              <span class="f-w-600" v-text="'ID: ' ">  </span>
              <span v-text="product.ecart_id ">  </span>
            </small>
          </p>
          <p class=" m-0 text-truncate f-w-400 " v-text=" product.name ">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      product: {
        type: Object,
        required: true,
      },

    }
  }
</script>

<style>

</style>